import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import Moment from "react-moment"
import "moment-timezone"
import "moment/locale/de"

const Format = styled.h3`
  margin-bottom: -2rem;
`
const EventLink = styled(Link)`
  color: black;
  text-decoration: none;
  :hover {
    color: #6594d4;
  }
`
class Event extends React.Component {
  constructor(props) {
    super(props)
    this.state = { voranmeldung: false, isAdditional: false }
  }
  render() {
    const {
      data: {
        prismic: { event },
      },
    } = this.props
    const locale = this.props.location.pathname.startsWith("/en/") ? "en" : "de"

    const speaker = event?.speaker

    /*
		if (data.day == data.end) {
			timespan = <h3>{data.day}</h3>
		} else {
			timespan = (
				<h3>
					{data.day} - {data.end}
				</h3>
			)
		}
    */
    return (
      <Layout location={this.props.location.pathname}>
        <SEO
          title={event.title[0].text}
          keywords={[
            `${event.title[0].text}`,
            `${event.format}`,
            "Event",
            "Talk",
            "Laboratory",
          ]}
        />
        <>
          <Format>
            {event.formatdescription && event.formatdescription
              ? event.formatdescription
              : event.format !== "Vortrag"
              ? event.format
              : ""}
          </Format>
          <h1>
            {event.title[0].text} {event.voranmeldung == "Ja" ? "* " : ""}
          </h1>
          <h2>{event.subtitle && event.subtitle[0].text}</h2>
          <h2>
            {(speaker || event.speakers[0].additional_speakers) &&
              (locale === "en" ? <>with </> : <>mit </>)}
            {speaker && (
              <>
                <EventLink to={`/speaker/${speaker._meta.uid}`}>
                  {speaker.title[0].text}
                </EventLink>
              </>
            )}
            {event.speakers[0].additional_speakers &&
              event.speakers.map((speaker, index, additionals) => (
                <>
                  {!speaker && <>, </>}
                  <EventLink
                    to={`/speaker/${speaker.additional_speakers._meta.uid}`}
                  >
                    {speaker.additional_speakers.title[0].text}
                  </EventLink>
                  {!(index === additionals.length - 1) && <>, </>}
                </>
              ))}
          </h2>
          <h2>
            {event.moderation && (
              <>
                {"Moderation: "}
                <EventLink to={`/speaker/${event.moderation._meta.uid}`}>
                  {event.moderation.title[0].text}
                </EventLink>
              </>
            )}
          </h2>
          {event.allday ? (
            event.start_time.substring(0, 9) ===
            event.end_time.substring(0, 9) ? (
              <p>
                <Moment format="dddd, DD.MM.yy" locale={locale}>
                  {event.start_time}
                </Moment>
              </p>
            ) : (
              <>
                <p>
                  <Moment format="dddd, DD.MM.yy" locale={locale}>
                    {event.start_time}
                  </Moment>
                  {" - "}
                  <Moment format="dddd, DD.MM.yy" locale={locale}>
                    {event.end_time}
                  </Moment>
                </p>
              </>
            )
          ) : event.start_time.substring(0, 9) ===
            event.end_time.substring(0, 9) ? (
            <>
              <p>
                <Moment format="dddd, DD.MM.yy" locale={locale}>
                  {event.start_time}
                </Moment>{" "}
                <Moment format="HH:mm" locale={locale}>
                  {event.start_time}
                </Moment>
                {" - "}
                <Moment format="HH:mm" locale={locale}>
                  {event.end_time}
                </Moment>
              </p>
            </>
          ) : (
            <>
              <p>
                <Moment format="dddd, DD.MM.yy, hh:mm " locale={locale}>
                  {event.start_time}
                </Moment>
                {" - "}
                <Moment format="dddd, DD.MM.yy, hh:mm " locale={locale}>
                  {event.end_time}
                </Moment>
              </p>
            </>
          )}
          {event.parallel ? <p>Parallel with other Events</p> : ""}

          {event.description && RichText.render(event.description)}
          <p>
            {event.voranmeldung === "Ja"
              ? "* Bei dieser Veranstaltung ist eine Voranmeldung notwendig und Sie werden nach erfolgreichem Ticketkauf über den Voranmeldungsprozess informiert!"
              : ""}
          </p>
        </>
      </Layout>
    )
  }
}

export default Event

export const pageQuery = graphql`
  query EventsBySlug($uid: String!, $lang: String!) {
    prismic {
      event(uid: $uid, lang: $lang) {
        voranmeldung
        title
        thumbnail
        start_time
        end_time
        description
        location
        speaker {
          ... on PRISMIC_Speaker {
            title
            _meta {
              uid
              id
            }
            thumbnail
          }
        }
        speakers {
          additional_speakers {
            ... on PRISMIC_Speaker {
              title
              thumbnail
              _meta {
                uid
                id
              }
            }
          }
        }
        moderation {
          ... on PRISMIC_Speaker {
            title
            excerpt
            role
            angefragt
            _meta {
              uid
            }
          }
        }
        parallel
        formatdescription
        eventlocation
        format
        allday
        subtitle
      }
    }
  }
`
